// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contactus-tsx": () => import("./../../../src/pages/contactus.tsx" /* webpackChunkName: "component---src-pages-contactus-tsx" */),
  "component---src-pages-event-tsx": () => import("./../../../src/pages/event.tsx" /* webpackChunkName: "component---src-pages-event-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-itmanager-tsx": () => import("./../../../src/pages/itmanager.tsx" /* webpackChunkName: "component---src-pages-itmanager-tsx" */),
  "component---src-pages-itvendor-tsx": () => import("./../../../src/pages/itvendor.tsx" /* webpackChunkName: "component---src-pages-itvendor-tsx" */),
  "component---src-pages-platform-tsx": () => import("./../../../src/pages/platform.tsx" /* webpackChunkName: "component---src-pages-platform-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-smbowner-tsx": () => import("./../../../src/pages/smbowner.tsx" /* webpackChunkName: "component---src-pages-smbowner-tsx" */),
  "component---src-pages-termsofuse-tsx": () => import("./../../../src/pages/termsofuse.tsx" /* webpackChunkName: "component---src-pages-termsofuse-tsx" */)
}

